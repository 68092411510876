<template>
  <div class="cols-12 col-xl-12" style="margin: 0 auto" id="aride">

    <form-wizard color="#7367F0" :title="null" :subtitle="null" finish-button-text="Request" back-button-text="Previous"
      next-button-text="Next" class="
        steps-transparent
        mb-3
        d-lg-flex d-xl-flex d-md-flex
        justify-content-center
        flex-xl-column
        formcreatepatient
      " @on-complete="formRequest" style="background-color: #fff" ref="requestTrip">



      <!-- tab booking type -->
      <tab-content title="Booking type" :before-change="validationTypeBooking">
        <validation-observer ref="bookingTypeRules" tag="form">
          <b-row>
            <b-col md="4" class="mt-4">
              <b-form-group label="Select the Booking type">
                <validation-provider #default="{ errors }" rules="required">
                  <v-select @close="loadCorporateAccountsMenu()" v-model="selectedTypeBooking"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="optionsTypeBooking" label="title"
                    placeholder="Please select some item" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- tab booking type -->


      <!-- tab Search Info -->
      <tab-content title="Search Info" :before-change="validationFormSearchInfo">
        <validation-observer ref="searchInfoRules" tag="form">
          <b-row>
            <b-col v-if="selectedTypeBooking.value == SELFPAY" md="4">
              <b-form-group label="Phone (e.g 0000000000)">
                <validation-provider #default="{ errors }" rules="required">
                  <v-select :disabled="disablePhone === true" v-model="currentPhone" :options="listPhones"
                    label="phone">
                    <template #option="{ phone }">
                      {{ phone }}
                    </template>
                  </v-select>
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="selectedTypeBooking.value == CORPORATE_ACCOUNT || selectedTypeBooking.value == CONTRACT"
              md="4">
              <b-form-group label="Select the company">
                <validation-provider #default="{ errors }" rules="required">
                  <v-select v-model="selectedCorporateAccount" :options="optionsCoporateAccount"
                    @close="getPatientsCorporateAccounts(selectedCorporateAccount.value)" label="title">
                  </v-select>
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
              <small class="text-danger" v-if="hasPatients == false">There are no patients for this company</small>
            </b-col>

            <b-col v-if="selectedTypeBooking.value == SELFPAY" md="4" class="text-right m-auto" style="display:block">
              <b-button variant="success" class="btn btn-primary" style="margin-top: 20px;" @click="createSelfpaid()">
                Create a new client
              </b-button>
            </b-col>

            <b-col v-if="selectedTypeBooking.value == SELFPAY" md="4 mt-2">
              <p class="text-danger" id="paragraphNumber">
                This phone number doesn't have an account yet, click on button
                to create an account
              </p>
            </b-col>
            <b-col v-if="selectedTypeBooking.value == SELFPAY" md="4 text-right m-auto">
              <b-button variant="success" class="btn btn-primary" @click="createSelfpaid()">
                Create Account
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- end tab Search Info -->


      <!-- tab contact info -->
      <tab-content title="Contact Info" :before-change="validationContactForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row v-if="selectedTypeBooking.value == SELFPAY">
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Contact Information</h5>
              <small class="text-muted" style="color: #000000d6 !important">Enter Contact Information.</small>
            </b-col>

            <b-col md="4">
              <b-form-group label="Name">

                <validation-provider #default="{ errors }" rules="required" name="Name">
                  <b-form-input v-model="firstNamePatient" disabled :state="errors.length > 0 ? false : null" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Last Name">
                <validation-provider #default="{ errors }" rules="required" name="Last Name">
                  <b-form-input v-model="lastNamePatient" disabled :state="errors.length > 0 ? false : null" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Contact Number">
                <validation-provider #default="{ errors }" rules="required" name="Contact Number">
                  <b-form-input v-model="contactPatient" :state="errors.length > 0 ? false : null" disabled />
                  <span style="color:#4100ff">{{ getIsVerified(is_verified_phone) }} </span>
                  <p class="text-right">
                    <small><a @click="submitPhoneCode(contactPatient)" class="bg-primary text-light rounded"
                        style="padding: 2px">Verify Phone</a></small>
                  </p>
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col md="4">
              <b-form-group label="Email">
                <validation-provider #default="{ errors }" rules="required|email" name="Email">
                  <b-form-input v-model="emailPatient" :state="errors.length > 0 ? false : null" disabled />
                  <span style="color:#4100ff">{{ getIsVerified(is_verified_email) }}</span>
                  <p class="text-right">
                    <small><a @click="submitEmailCode(emailPatient)" class="bg-primary text-light rounded"
                        style="padding: 2px">Verify Email</a></small>
                  </p>

                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>

            </b-col>
          </b-row>

          <b-row v-if="selectedTypeBooking.value == CORPORATE_ACCOUNT || selectedTypeBooking.value == CONTRACT">
            <b-col cols="12" class="mb-2">
              <h5 class="text-danger" v-if="hasPatients == false">There are no patients for this company</h5>
              <h5 class="mb-0">Patient information</h5>

              <small class="text-muted" style="color: #000000d6 !important">Enter patient information.</small>
            </b-col>

            <b-col v-if="selectedTypeBooking.value == CORPORATE_ACCOUNT || selectedTypeBooking.value == CONTRACT"
              md="12" class="text-right m-auto" style="display:block">
              <b-button variant="success" class="btn btn-primary mb-2" @click="createPatient()">
                Create Patient
              </b-button>
            </b-col>

            <b-col md="4">
              <b-form-group label="Name">
                <validation-provider #default="{ errors }" rules="required">
                  <v-select v-model="idpaciente" label="title" :options="optionsPatients"
                    :state="errors.length > 0 ? false : null">
                  </v-select>
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Last Name">
                <validation-provider #default="{ errors }" rules="required" name="lastNamePatient">
                  <b-form-input v-model="lastNamePatient" disabled :state="errors.length > 0 ? false : null" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Contact Number">
                <validation-provider #default="{ errors }" rules="required" name="contactPatient">
                  <b-form-input v-model="contactPatient" :state="errors.length > 0 ? false : null" disabled />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Email">
                <b-form-input v-model="emailPatient" disabled />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- contact info -->


      <!-- tab Preschedule -->
      <tab-content title="Preschedule" :before-change="validationFormInfoRequest">
        <validation-observer ref="infoRulesRequest" tag="form">
          <b-row>

            <b-col md="3" cols="12" class="mb-2">
              <h5 v-if="selectedCorporateAccount != null" class="mb-0">Company</h5>
              <p v-if="selectedCorporateAccount != null" class="mb-0">{{ selectedCorporateAccount.title }}</p>
              <h5 class="mb-0">Preschedule date</h5>
              <p class="mb-0">Patient: {{ firstNamePatient }} {{ lastNamePatient }}</p>
              <p class="mb-0">Phone: {{ contactPatient }}</p>
              <p class="mb-0">Email: {{ emailPatient }}</p>
              <small class="text-muted"></small>

              <table>
                <tr>
                  <td>
                    <p class="mb-0">
                      <span style="font-weight: bold">Reference number:</span>
                    </p>
                  </td>
                  <td>
                    <b-form-input v-model="dataRequest.reference_number" size="sm" md="2" />
                  </td>
                </tr>
              </table>

            </b-col>


            <!-- Resumen Distance an location -->
            <b-col md="9" cols="12" class="display: flex;">
              <table class="tbTotal">
                <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
                  <td>
                    <span style="font-weight: bold"> Pickup address: </span>
                  </td>
                  <td>
                    {{ dataRequest.from }}
                  </td>
                </tr>
                <tr v-if="dataRequest.from !== '' && dataRequest.to !== ''">
                  <td>
                    <span style="font-weight: bold"> Destination: </span>
                  </td>
                  <td>
                    {{ dataRequest.to }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="font-weight: bold"> Distance: </span>
                  </td>
                  <td>
                    <span v-if="dataRequest.is_automatic_miles == 1"> {{ toFixed(dataRequest.total_miles, 0) }}
                      miles</span>
                    <b-form-input type="number" ref="input-price" style="width: 170px;"
                      v-if="dataRequest.is_automatic_miles == 0" v-model="dataRequest.total_miles" size="sm" md="2" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="font-weight: bold"> Calculation distance: </span>
                  </td>
                  <td>
                    <div style="display:flex">

                      <b-form-radio @change="doCalculations()" v-model="dataRequest.is_automatic_miles"
                        name="radios-automatic-miles" value="1">Automatic distance
                      </b-form-radio>
                      <b-form-radio class="ml-1 mr-1" v-model="dataRequest.is_automatic_miles"
                        name="radios-automatic-miles" value="0">Fixed distance</b-form-radio>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="font-weight: bold"> Price:</span>
                  </td>
                  <td>
                    <span v-if="dataRequest.is_automatic_price == 1"> {{ toFixed(dataRequest.price, 2) }}</span>
                    <b-form-input type="number" ref="input-price" style="width: 170px;"
                      :disabled="this.$hasPermission('modifying-rates') === false"
                      v-if="dataRequest.is_automatic_price == 0" v-model="dataRequest.price" size="sm" md="2" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="font-weight: bold"> Calculation price: </span>
                  </td>
                  <td>
                    <div style="display:flex">
                      <b-form-radio @change="calculatePrice" v-model="dataRequest.is_automatic_price"
                        :disabled="this.$hasPermission('modifying-rates') === false" name="radios-automatic-price"
                        value="1">Automatic price
                      </b-form-radio>
                      <b-form-radio class="ml-1 mr-1" v-model="dataRequest.is_automatic_price"
                        :disabled="this.$hasPermission('modifying-rates') === false" name="radios-automatic-price"
                        value="0">Fixed price</b-form-radio>
                    </div>
                  </td>
                </tr>
                <tr v-if="tripSupported === 'no'">
                  <td>
                    <span style="font-weight: bold">Trip Supported</span>
                  </td>
                  <td>
                    {{ tripSupported }}
                  </td>
                </tr>
              </table>
            </b-col>
            <!--End Resumen Distance an location -->

            <b-col md="12">
              <b-row>
                <b-col md="4">
                  <b-form-group label="Trip type">
                    <validation-provider #default="{ errors }" rules="required">
                      <v-select @close="doCalculations()" v-model="selectedTripType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="optionsTriptype" label="title"
                        placeholder="Please select some item" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger" v-if="errors[0]">This field is required</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Service type">
                    <validation-provider #default="{ errors }" rules="required" name="Service Type">
                      <v-select v-model="selectedService" :options="optionsServiceOptions" label="title"
                        placeholder="Service Type" @close="changeServiceType()" />
                      <small class="text-danger" v-if="errors[0]">{{
                        errors[0]
                        }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Reason of Visit">
                    <validation-provider #default="{ errors }" rules="required" name="Reason of Visit">
                      <v-select v-model="selectedSurgery" :options="optionsSurgery" label="title"
                        placeholder="Please select some item" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger" v-if="errors[0]">This field is required</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="5" lg="6">
                  <b-form-group label="Pickup time">
                    <validation-provider #default="{ errors }" rules="required" name="Pickup time">
                      <v-select v-model="selectedIsPickupOntime" :options="optionsIsPickupOntime" label="title"
                        placeholder="Please select some item" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger" v-if="errors[0]">This field is required</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="It's walk in">
                    <b-form-select @change="setValuesWalkIn()" v-model="dataRequest.is_walkin" :options="optionsWalkIn">
                    </b-form-select>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>

            <b-col md="4">
              <b-form-group label="Facility Name">
                <validation-provider #default="{ errors }" rules="" name="Facility Name">
                  <b-form-input :state="errors.length > 0 ? false : null" v-model="dataRequest.facility_name"
                    id="facility_name" :maxlength="50" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Doctor's Name">
                <validation-provider #default="{ errors }" rules="" name="Doctor's Name">
                  <b-form-input v-model="dataRequest.doctor_name" :state="errors.length > 0 ? false : null"
                    id="doctor_name" :maxlength="50" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Facility Phone Number">
                <validation-provider #default="{ errors }" rules="" name="Facility Phone Number">
                  <cleave id="phone" v-model="dataRequest.facility_phone_number" class="form-control" :raw="false"
                    :options="optionsCleave.phone" placeholder="1234 567 8900" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Pick up location (e.g. aparment #4432, house color blue)">
                <b-form-input v-model="dataRequest.pick_up_location" id="pick_up_location" :maxlength="250" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Drop off location (e.g. aparment #4432, house color blue)">
                <b-form-input v-model="dataRequest.drop_off_location" id="drop_off_location" :maxlength="250" />
              </b-form-group>
            </b-col>

            <b-col md="6">

              <div style="display:flex" class="mb-2">
                <b-form-radio v-model="dataRequest.is_automatic_pickup_address" name="radios-automatic-pickup-address"
                  value="1">Automatic pickup address
                </b-form-radio>
                <b-form-radio class="ml-1 mr-1" v-model="dataRequest.is_automatic_pickup_address"
                  name="radios-automatic-pickup-address" value="0">Manual pickup address</b-form-radio>
              </div>

              <template v-if="dataRequest.is_automatic_pickup_address == 1" class="col-6">
                <b-form-group label="Pick up address">
                  <b-form-input disabled v-model="dataRequest.from" />
                  <gmap-autocomplete class="form-control" :value="searchPickupAddress" @place_changed="initMarkerFrom">
                  </gmap-autocomplete>
                </b-form-group>
              </template>


              <template v-if="dataRequest.is_automatic_pickup_address == 0">
                Pickup address
                <b-form-input v-model="dataRequest.from" @change="doCalculations()" />
                <table>
                  <tr>
                    <td>
                      <span>Latitude</span>
                    </td>
                    <td>
                      <span class="ml-2">Longitude</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <validation-provider #default="{ errors }" rules="required" name="Latitude">
                        <b-form-input v-model="fromLatitude" @change="doCalculations()" />
                        <small class="text-danger" v-if="errors[0]">{{
                          errors[0]
                          }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <validation-provider #default="{ errors }" rules="required" name="Latitude">
                        <b-form-input class="ml-2" v-model="fromLongitude" @change="doCalculations()" />
                        <small class="text-danger ml-2" v-if="errors[0]">{{
                          errors[0]
                          }}</small>
                      </validation-provider>
                    </td>
                  </tr>
                </table>
              </template>


            </b-col>

            <b-col md="6">
              <div style="display:flex" class="mb-2">
                <b-form-radio v-model="dataRequest.is_automatic_destination_address"
                  name="radios-automatic-destination-address" value="1">Automatic destination address
                </b-form-radio>
                <b-form-radio class="ml-1 mr-1" v-model="dataRequest.is_automatic_destination_address"
                  name="radios-automatic-destination-address" value="0">Manual pickup address</b-form-radio>
              </div>

              <template v-if="dataRequest.is_automatic_destination_address == 1" class="col-6">
                <b-form-group label="Destination address">
                  <b-form-input disabled v-model="dataRequest.to" />
                  <gmap-autocomplete class="form-control" :value="searchDestinationAddress"
                    @place_changed="initMarkerTo">
                  </gmap-autocomplete>
                </b-form-group>
              </template>


              <template v-if="dataRequest.is_automatic_destination_address == 0">
                Destination address
                <b-form-input v-model="dataRequest.to" @change="doCalculations()" />
                <table>
                  <tr>
                    <td>
                      <span>Latitude</span>
                    </td>
                    <td>
                      <span class="ml-2">Longitude</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <validation-provider #default="{ errors }" rules="required" name="Latitude">
                        <b-form-input v-model="toLatitude" @change="doCalculations()" />
                        <small class="text-danger" v-if="errors[0]">{{
                          errors[0]
                          }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <validation-provider #default="{ errors }" rules="required" name="Longitude">
                        <b-form-input class="ml-2" v-model="toLongituded" @change="doCalculations()" />
                        <small class="text-danger ml-2" v-if="errors[0]">{{
                          errors[0]
                          }}</small>
                      </validation-provider>
                    </td>
                  </tr>
                </table>
              </template>


            </b-col>

            <b-col md="3">
              <b-form-group label="State">
                <validation-provider #default="{ errors }" rules="required">
                  <v-select v-model="selectedState" label="title" :options="listStates"
                    @close="getCitiesByState(selectedState.value, '')" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="City">
                <validation-provider #default="{ errors }" rules="required">
                  <v-select @input="setTimeZone(null)" v-model="selectedCity" label="title" :options="listCities"
                    :disabled="listCities.length == 0" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Time zone">
                <validation-provider #default="{ errors }" rules="required">
                  <v-select v-model="selectedTimeZone" label="title" :options="optionsTimezone" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="5" lg="3">
              <b-form-group>
                <validation-provider #default="{ errors }" rules="required|regex:^[0-9][0-9]:[0-9][0-9]$"
                  name="Waiting time">
                  <label for="" style="margin-bottom: 10px">Waiting time(hh:mm) Price ${{ feeWaitingTime }}/hour</label>
                  <b-form-input @change="calculatePrice()" v-model="dataRequest.waitingTime" v-mask="'##:##'"
                    hint="hh:mm" placeholder="hh:mm" />
                  <small class="text-danger" v-if="errors[0]">{{
                    errors[0] + " hh:mm"
                    }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Appointment date">
                <validation-provider #default="{ errors }" rules="required" name="Appointment date">
                  <b-form-datepicker v-model="appointmentdate" locale="en" placeholder="0/00/0000"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" lg="3" v-if="dataRequest.is_walkin == 0">
              <b-form-group label="Appointment time">
                <validation-provider #default="{ errors }" rules="required" name="Appointment time">
                  <input v-model="appointmenttime" type="time" lang="en-us"
                    style="padding: 8px; border-radius: 9px; border: 2px solid #ececec;" />
                  <br>
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" lg="3">
              <b-form-group label="Approximate pick up time">
                <validation-provider #default="{ errors }" rules="required" name="Suggested pick up time">
                  <input v-model="dataRequest.pickup_time" type="time" lang="en-us"
                    style="padding: 8px; border-radius: 9px; border: 2px solid #ececec;" />
                  <br>
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="3" v-if="selectedTripType.value == ROUNTRIP && dataRequest.is_walkin == 0">
              <b-form-group label="Approximate return time">
                <validation-provider #default="{ errors }" rules="required" name="Approximate return time">
                  <input v-model="dataRequest.approximately_return_time" type="time" lang="en-us"
                    style="padding: 8px; border-radius: 9px; border: 2px solid #ececec;" />
                  <br>
                  <small class="text-danger" v-if="errors[0]">This field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Driver price">
                <b-form-input v-model="dataRequest.driver_price" @keypress="isNumberVar($event)" :maxlength="8"
                  id="driver_price" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Additional charges driver">
                <b-form-input v-model="dataRequest.additional_charges_driver" @keypress="isNumberVar($event)"
                  :maxlength="8" id="driver_price" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="No show/Cancelation fee for driver">
                <b-form-input v-model="dataRequest.no_show_cancelation_fee_driver" @keypress="isNumberVar($event)"
                  :maxlength="8" />
              </b-form-group>
            </b-col>

            <!-- Additional stops -->
            <b-col md="12">
              <h5 class="mb-0">
                Additional stops
              </h5>
              <br />
            </b-col>
            <b-col md="5">
              <b-form-group>
                <gmap-autocomplete :value="searchAdditionalStop" class="form-control"
                  @place_changed="initMarkerAdditionalStop">
                </gmap-autocomplete>
              </b-form-group>
            </b-col>
            <b-col md="7">
              <b-button variant="success" @click="addAdditionalStop">
                Add additional stop
              </b-button>
            </b-col>
            <b-col md="12" v-if="dataRequest.listAdditionalStops.length > 0">
              <h5 class="mb-0">List Additional stops</h5>
              <br />
            </b-col>
            <b-col md="12" v-if="dataRequest.listAdditionalStops.length > 0">
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>Additional stop info</b-th>
                    <b-th></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="item in dataRequest.listAdditionalStops" :key="item.id">
                    <b-td> {{ item.to }}</b-td>
                    <b-td>
                      <b-button variant="danger" class="btn btn-primary" @click="deleteAdditionalStop(item)">
                        Delete
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <!-- End Additional stops -->
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- end tab Preschedule -->


      <!-- tab Payment -->
      <tab-content v-if="selectedTypeBooking.value != CONTRACT" title="Payment" :before-change="validationFormPayment">
        <validation-observer ref="paymentRules" tag="form">

          <template v-if="selectedTypeBooking.value == SELFPAY">

            <b-row>
              <b-col>
                <h5>Do you want to pre-authorize the payment?</h5>
              </b-col>
            </b-row>
            <b-row class="mt-2 mb-4">
              <b-form-radio v-model="create_invoice" name="some-radios" value="1" class="ml-2">
                Yes, pre-authorize the payment
              </b-form-radio>
              <b-form-radio v-model="create_invoice" name="some-radios" value="0" class="ml-3">
                No, please do not pre-authorize yet
              </b-form-radio>
            </b-row>
          </template>



          <b-row v-if="create_invoice == '1' || selectedTypeBooking.value == CORPORATE_ACCOUNT">
            <b-col cols="12" class="mb-2">
              <h3 class="mb-2">Payment Information</h3>
              <div>
                <span style="background: #ee8b52;color: white;padding: 10px;" v-b-modal.modal-edit-payments>Add or edit
                  payments
                  methods</span>

                <h3 class="mt-2">{{ statusPageListPaymentMethods }}</h3>

                <template v-if="listPaymentMethods.length > 0">
                  <BookingPaymentsMethod class="mt-4" :key="keyBookingPaymentsMethod"
                    :idCustomerPaymentMehtod="selectIdCustomerPaymentMehtod"
                    :isSelfpay="this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT ? false : true"
                    :proPaymentsMethods="listPaymentMethods"
                    v-on:setSeletedPaymentMethod="setSeletedPaymentMethod($event)"></BookingPaymentsMethod>
                </template>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-input style="display:none" v-model="selectedPaymentMethodId"
                    :state="errors.length > 0 ? false : null" disabled />
                </validation-provider>

                <h4 v-if="isTherePaymentMethods == false" style="color:red" class="mt-4">There are no payments methods
                </h4>

              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- end tab Payment -->


      <!-- tab confirmation -->
      <tab-content title="Confirmation">
        <!-- Applicant -->
        <b-row class="invoice-heading">
          <b-col md="12">
            <h3 class="">Contact Information</h3>
          </b-col>
        </b-row>

        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Name</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ firstNamePatient }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Last Name</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ lastNamePatient }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Contact Number</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ contactPatient }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Email</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ emailPatient }}</h4>
          </b-col>
        </b-row>

        <!-- Information -->
        <b-row class="invoice-heading">
          <b-col md="12">
            <h3 class="">Facility Information</h3>
          </b-col>
        </b-row>

        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Facility Name</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ dataRequest.facility_name }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Doctor's Name</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ dataRequest.doctor_name }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Facility Phone Number</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ dataRequest.facility_phone_number }}</h4>
          </b-col>
        </b-row>

        <!-- preschedule -->
        <b-row class="invoice-heading">
          <b-col md="12">
            <h3 class="">Booking details</h3>
          </b-col>
        </b-row>

        <b-row class="row-data"> </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Trip Type</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">
              {{ selectedTripType ? selectedTripType.title : "" }}
            </h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Trip Distance(miles)</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ Math.round(dataRequest.total_miles) }}</h4>
          </b-col>
        </b-row>

        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Waiting time(hh:mm) </h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ dataRequest.waitingTime }}</h4>
          </b-col>
        </b-row>

        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Additional Stops</h4>
          </b-col>
          <b-col md="6" v-if="dataRequest.listAdditionalStops.length > 0" class="text-right">
            <b-col v-for="item in dataRequest.listAdditionalStops" :key="item.id" md="12" class="text-right">
              <h4 class="">*{{ item.to }}</h4>
            </b-col>
          </b-col>
        </b-row>

        <b-row class="row-data" v-if="dataRequest.price">
          <b-col md="6">
            <h4 class="">Price</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">${{ toFixed(dataRequest.price, 2) }}</h4>
          </b-col>
        </b-row>

        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Pickup Address</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="" id="initMarkerToId2">{{ dataRequest.from }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Destination</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="" id="initMarkerFromId2">{{ dataRequest.to }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Surgery Type</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ selectedSurgery ? selectedSurgery.title : "" }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Appointment date</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ appointmentdate }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data" v-if="dataRequest.is_walkin == 0">
          <b-col md="6">
            <h4 class="">Appointment time</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 v-if="selectedTripType" class="">{{ appointmenttime }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data">
          <b-col md="6">
            <h4 class="">Suggested pick up time</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ dataRequest.pickup_time }}</h4>
          </b-col>
        </b-row>
        <b-row class="row-data" v-if="show === true">
          <b-col md="6">
            <h4 class="">Price</h4>
          </b-col>
          <b-col md="6" class="text-right">
            <h4 class="">{{ resultValor }}</h4>
          </b-col>
        </b-row>
      </tab-content>
      <!-- end *tab confirmation -->
    </form-wizard>

    <b-modal ref="modalcreaterates" cancel-only centered size="sm" title="There are no rates for this trip">
      To create a rate visit this page
      <span style="color: #9214b5;cursor: pointer;" @click="openCreateRate">Create rate</span>

      <template #modal-footer>
        <div class="w-100">
        </div>
      </template>
    </b-modal>

    <b-modal id="modal-phone" centered hide-footer>
      <div class="d-block text-center">
        <h3>Enter 5 digits code received to user</h3>
        <b-col>
          <b-form-group>
            <b-form-input v-model="codePhone" @mouseover="changeColorCode(code)" />
          </b-form-group>
        </b-col>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="verifyPhoneCode(codePhone, contactPatient)">Verify</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('modal-validate-email')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-validate-email" centered hide-footer>
      <div class="d-block text-center">
        <h3>Enter 5 digits code received to user</h3>
        <b-col>
          <b-form-group>
            <b-form-input v-model="codeEmail" @mouseover="changeColorCode(codeEmail)" />
          </b-form-group>
        </b-col>
        <h4>Make sure the contact checks also in the spam folder in case the email is not visible in the inbox. </h4>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="verifyEmail(codeEmail)">Verify</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('modal-validate-email')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-edit-payments" centered hide-footer size="xl">
      <b-row style="display: flex;flex-direction: column;margin:20px">

        <template v-if="client_id != null">
          <PaymentMethodSelfPaid v-if="this.selectedTypeBooking.value == this.SELFPAY"
            v-on:updatePaymentMethodList="updatePaymentMethodList($event)" :key="keyPageEditPaymentSelfpay"
            :proclientId="client_id" :proPaymentMethods="listPaymentMethods"></PaymentMethodSelfPaid>

          <template v-if="selectedCorporateAccount">
            <CaListPaymentMethod v-if="this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT"
              v-on:updatePaymentMethodList="updatePaymentMethodList()" :proListPaymentMethods="listPaymentMethods"
              :proPaymentType="selectedTypeBooking.value == this.CORPORATE_ACCOUNT ? 'cc' : 'il'"
              :proStripeCustomerId="stripeCustomerId" :proCaid="selectedCorporateAccount.value"
              :key="keyPageEditPaymentCa" :proShowEditPaymentMethod="false">
            </CaListPaymentMethod>
          </template>
        </template>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { getMessageError } from "@core/utils/utils";
import { toFixed } from "@core/utils/numbers_utils";
import { addAddressToList } from "@core/utils/gps_utils";

import BookingPaymentsMethod from '@core/components/bookings/BookingPaymentsMehod'
import PaymentMethodSelfPaid from '@/views/Pages/admin/PaymentMethodSelfPaid'
import CaListPaymentMethod from '@/@core/components/CA/CaListPaymentMethod.vue'

import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormDatepicker,
  BFormTimepicker,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTfoot,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import {
  getMenuOptionsTripType,
  getMenuOptionsSurgeryOptions,
  getMenuOptionsServiceType,
  getMenuYesNo,
  getMenuPickupOnTime,
  getMenuTimeZones,
  getMenuTypeBooking,
  getMenuTime
} from "@core/utils/menus";
import { getCentralTime } from "@core/utils/utils";
import Cleave from 'vue-cleave-component'
require('cleave.js/dist/addons/cleave-phone.us');

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormSelect,
    BFormDatepicker,
    BFormTimepicker,
    vSelect,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BForm,
    Cleave,
    BFormTextarea,
    BookingPaymentsMethod,
    PaymentMethodSelfPaid,
    CaListPaymentMethod
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 1);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 2);
    maxDate.setDate(15);
    return {
      searchingNumber: "",
      dir: "ltr",
      dataCa: {},
      searchDestinationAddress: null,
      postData: {
        number: "",
      },
      currentPhone: null,
      dataRequest: {
        booking_date: "",
        from: "",
        to: "",
        pickup_time: "",
        city: "",
        surgery_type: "",
        appoinment_datetime: "",
        to_coordinates: "",
        from_coordinates: "",
        trip_distance: 0,
        service_fee: 0,
        price: 0.0,
        facility_name: "",
        doctor_name: "",
        facility_phone_number: "",
        approximately_return_time: "",
        status: "",
        selfpay_id: "",
        notes: "",
        service_type: "",
        waitingTime: "00:00",
        total_additional_stops: 0,
        totalWaitingTime: 0,
        total_miles: 0,
        listAdditionalStops: [],
        is_pickup_ontime: 0,
        pick_up_location: "",
        drop_off_location: "",
        state: "",
        city: "",
        time_zone: "",
        garage_time: "",
        approximately_return_time: "",
        is_automatic_price: "1",
        is_automatic_miles: "1",
        is_walkin: 0,
        is_automatic_pickup_address: 1,
        is_automatic_destination_address: 1,
      },
      tripSupported: null,
      disablebUpdateButton: true,
      disablePhone: true,
      pricesSettings: null,

      //GOOGLE MAPS
      getlocationlong: "",
      getlocationlati: "",
      getlocationFromLong: "",
      getlocationFromLati: "",
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: {},
      gps_address: "",
      address: "",

      //ADDITIONAL POINTS
      additionalStop: null,
      searchAdditionalStop: null,
      searchPickupAddress: null,

      //PATIENT INFORMATION
      lastNamePatient: "",
      firstNamePatient: "",
      contactPatient: "",
      emailPatient: "",
      is_verified_email: "",
      is_verified_phone: "",

      //OTHERS
      resultValor: 0,
      show: false,
      searchWait: "",
      millas: 0.621371,
      notes: "",
      phoneFieldEmpty: true,
      segundos: 1800, //30 minutos
      tiempoEstimado: 0,
      ubicacion: "",
      idpaciente: 0,

      fecha: "",
      tiempo: "",
      appointmentdate: "",
      appointmenttime: "",
      min: minDate,
      max: maxDate,
      appointment: "",
      getInfoPat: [],

      //LIST DATA
      listAddresses: [],
      listAddressesOrdered: [],
      listStates: [],
      listCities: [],
      listPatients: [],
      listSelfpay: [],
      listCorporateAccounts: [],
      listPhones: [],
      dataCities: [],

      //FIELDS PAYMENTS
      reset: false,
      paymentMethods: {
        name_on_cc: "",
        cc_number: "",
        brand: "",
        code_of_cc: "",
        exp: "",
        exp_month: "00",
        exp_year: "",
        last4: "",
        card_use: "",
      },
      cargando: true,
      inhabilitar: true,
      showData: true,
      saveData: false,
      newData: false,
      client: "",
      client_id: 0,
      card_use: "",
      create_invoice: 1,

      //FEES
      feeAdditionalStop: 0,
      feeWaitingTime: 0,
      feeAdditionalStop: 0,
      feeWaitingTime: 0,

      //OPTIONS
      optionsTriptype: [],
      optionsSurgery: [],
      optionsServiceOptions: [],
      optionsIsPickupOntime: [],
      optionsTypeBooking: [],
      optionsTimezone: [],
      optionsCardUsed: [
        {
          text: "Business credit card",
          value: "bc",
        },
        {
          text: "Personal credit card",
          value: "pc",
        },
      ],
      optionsCoporateAccount: [],
      optionsCleave: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      optionsPatients: [],

      //SELETED OPTIONS
      selectedState: { title: '', value: '' },
      selectedCity: null,
      selectedTimeZone: null,
      seletedCardUsed: null,
      selectedTripType: { title: '', value: '' },
      selectedSurgery: null,
      selectedIsPickupOntime: null,
      selectedTypeBooking: {
        value: "",
        title: ""
      },
      selectedCorporateAccount: {
        value: "",
        title: ""
      },
      seletedPatient: null,
      selectedService: {},

      //CONSTANTS
      SELFPAY: 'selfpay',
      CORPORATE_ACCOUNT: 'corporate_account',
      CONTRACT: 'contract',
      ROUNTRIP: 'roundtrip',
      //WARNINGS
      hasPatients: true,

      codeEmail: "",
      codePhone: "",
      optionsWalkIn: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      fromLatitude: null,
      fromLongitude: null,
      toLatitude: null,
      toLongituded: null,
      listPaymentMethods: [],
      keyBookingPaymentsMethod: 0,
      keyPageEditPaymentSelfpay: 0,
      keyPageEditPaymentCa: 0,
      selectIdCustomerPaymentMehtod: 0,
      statusPageListPaymentMethods: "",
      stripeCustomerId: null,
      invoiceLaterNote: null,
      isTherePaymentMethods: true,
      selectedPaymentMethodId: null
    };
  },
  watch: {
    currentPhone() {
      if (this.currentPhone == null) {
        return false;
      }

      if (this.currentPhone) {
        let data=this.SetPhoneNumberWatch();
        //let data = this.listSelfpay.find(x => x.phone_number == this.currentPhone.phone);

      }

    },
    idpaciente() {
      if (this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT || this.selectedTypeBooking.value == this.CONTRACT) {

        let seletedIdPatient = 0;
        if (this.idpaciente) {
          seletedIdPatient = this.idpaciente.value;
        }
        this.SetpacienteWatch(parseInt(seletedIdPatient));
        //let data = this.listPatients.find(x => x.id === parseInt(seletedIdPatient));
        //this.loadPatientInformation(data);
      }
      if (this.selectedTypeBooking.value == this.SELFPAY) {
        if (this.idpaciente != null) {
          this.getListPaymentMethods(true, this.idpaciente.value);
        }

      }
    },
    selectedCorporateAccount() {
      if (this.selectedCorporateAccount) {
        let ca = this.listCorporateAccounts.find(x => x.id == this.selectedCorporateAccount.value);
        if (ca) {
          this.stripeCustomerId = ca.corporate_account_personal_info.stripe_customer_id;
          this.invoiceLaterNote = ca.corporate_account_personal_info.invoice_later;
        }

        if (this.selectedCorporateAccount.value != null) {
          this.getListPaymentMethods(false, this.selectedCorporateAccount.value);
        }
        //this.getCard(this.selectedCorporateAccount.value)
      }
    },
    selfpay_id() {
      let { selfpay_id, status } = this.$store.getters["Users/userData"];
      this.dataCa.selfpay_id = selfpay_id;
      this.dataCa.status = status;
    },
    tiempoEstimado() {
      if (
        this.dataRequest.from !== "" &&
        this.dataRequest.to !== "" &&
        this.tiempoEstimado !== 0
      ) {
        let resultSegundos = this.tiempoEstimado + this.segundos;
        let getMinutos = resultSegundos / 60;
        let horas = this.tiempo.slice(0, 2);
        let minutos = this.tiempo.slice(3, 5);
        let horaMin = horas * 60;
        let valorEnminutos = horaMin - Math.round(getMinutos);
        let pasar = valorEnminutos * 60;
        let hour = Math.floor(pasar / 3600);
        hour = hour < 10 ? "0" + hour : hour;
        let minute = Math.floor((pasar / 60) % 60);
        minute = minute < 10 ? "0" + minute : minute;
        let second = pasar % 60;
        second = second < 10 ? "0" + second : second;
        this.dataRequest.pickup_time = hour + ":" + minute + ":" + second;
        this.timeReturn();
      }
    },
  },
  computed: {
    infopersonaselec() {
      for (let listSelfpayKey of this.listSelfpay) {
        if (listSelfpayKey.id === listSelfpayKey.id) {
          let arrat = this.listSelfpay;
          for (let ki of arrat) {
            if (ki.id === listSelfpayKey.id) {
            }
          }
        }
      }
    },
  },
  methods: {
    //INIT METHODS
    initMarker(loc) {
      this.existingPlace = loc;
      this.dataRequest.city = this.existingPlace.formatted_address;
    },
    initMarkerTo(loc) {
      this.existingPlace = loc;
      this.dataRequest.to = this.existingPlace.formatted_address;
      this.dataRequest.to_coordinates =
        this.existingPlace.geometry.location.lat() +
        "," +
        this.existingPlace.geometry.location.lng();

      this.toLatitude = this.existingPlace.geometry.location.lat();
      this.toLongituded = this.existingPlace.geometry.location.lng();
      this.doCalculations();
      this.searchPickupAddress = null;
    },
    initMarkerFrom(loc) {
      this.existingPlace = loc;
      this.setStateCity(loc);

      this.dataRequest.from = this.existingPlace.formatted_address;
      this.dataRequest.from_coordinates =
        this.existingPlace.geometry.location.lat() +
        "," +
        this.existingPlace.geometry.location.lng();

      this.fromLatitude = this.existingPlace.geometry.location.lat();
      this.fromLongitude = this.existingPlace.geometry.location.lng();
      this.doCalculations();

      let hourestimado = Math.floor(this.tiempoEstimado / 3600);
      hourestimado = hourestimado < 10 ? "0" + hourestimado : hourestimado;
      let minutetimado = Math.floor((this.tiempoEstimado / 60) % 60);
      minutetimado = minutetimado < 10 ? "0" + minutetimado : minutetimado;
      let secondestimado = this.tiempoEstimado % 60;
      secondestimado =
        secondestimado < 10 ? "0" + secondestimado : secondestimado;
      this.dataRequest.approximately_return_time =
        hourestimado + ":" + minutetimado + ":" + secondestimado;
      this.searchDestinationAddress = null;
    },
    initMarkerAdditionalStop(loc) {
      let existingPlace = loc;
      let to = existingPlace.formatted_address;
      let coordinate =
        existingPlace.geometry.location.lat() +
        "," +
        existingPlace.geometry.location.lng();

      this.additionalStop = {
        to: to,
        coordinate: coordinate,
      };
    },
    //VALIDATIONS
    validationContactForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationTypeBooking() {
      return new Promise((resolve, reject) => {
        this.$refs.bookingTypeRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSearchInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.searchInfoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormPayment() {
      if (this.dataRequest.price === 0 && this.create_invoice === 1) {
        return new Promise((resolve, reject) => {
          this.$swal({
            title: "The booking price must be greater than 0 to pre-authorize the payment",
          });
          reject();
        });
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.paymentRules.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              this.$swal({
                title: "Must select a payment method",
              });
              reject();
            }
          });
        });
      }



    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.paymentRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            this.$swal({
              title: "Must select a payment method",
              didOpen: () => {
                this.$swal.showLoading();
              },
            });

            reject();
          }
        });
      });
    },
    validationNumber() {
      return new Promise((resolve, reject) => {
        this.$refs.accountNumber.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    async validationFormInfoRequest() {
      //validate destine address
      if (
        this.dataRequest.from_coordinates === "" ||
        this.dataRequest.from_coordinates === null
      ) {
        let message = "Must select a pickup address";
        this.$swal({
          title: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }
      //validate pickup address
      if (
        this.dataRequest.to_coordinates === "" ||
        this.dataRequest.to_coordinates === null
      ) {
        let message = "Must select a Destination";
        this.$swal({
          title: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        return false;
      }

      await this.calculatePrice();

      return new Promise((resolve, reject) => {
        self = this;
        this.$refs.infoRulesRequest.validate().then((success) => {
          if (success) {
            if (self.tripSupported === "no" && (self.dataRequest.is_automatic_price == 1 || self.dataRequest.is_automatic_price == "1")) {
              self.$refs.modalcreaterates.show();
              reject();
            } else {
              resolve(true);
            }
          } else {
            reject();
          }
        });
      });
    },
    //GET INFORMATION
    async getCorporateAccounts(showModal = true) {
      let pagination = "false";
      if (showModal == true)

        await this.$http
          .get(`/admin/panel/ca/list/${pagination}`)
          .then((response) => {
            this.listCorporateAccounts = response.data.data;
            this.loadCorporateAccountsMenu();


          })
          .catch((res) => {
            let message = getMessageError(res);
            this.$swal({
              title: message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
    },
    async getPatientsCorporateAccounts(idCorporateAccount) {
      idCorporateAccount = String(idCorporateAccount);
      if (idCorporateAccount == null || idCorporateAccount.trim().length == 0)
        return false;
      let pagination = "0";
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      await this.$http
        .get(`/admin/panel/${idCorporateAccount}/client/search/${pagination}`)
        .then((response) => {
          this.listPatients = response.data.data;
          this.loadOptionsPatient(this.listPatients);
          this.idpaciente = null;
          this.$swal.close();

          if (this.listPatients.length == 0) {
            this.hasPatients = false;
          } else {
            this.hasPatients = true;
          }

        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    loadOptionsPatient(data) {
      this.optionsPatients = [];
      data.forEach(
        (x) => {
          let value = x.id;
          let title = `${x.name} ${x.lastname}`;
          this.optionsPatients.push({ value: value, title: title });
        }
      )
    },
    getPricesSettings() {
      if (this.optionsTriptype.length == 0) {
        return false;
      }

      let miles = this.dataRequest.total_miles;
      let tripType =
        this.selectedTripType == null
          ? this.optionsTriptype[0].value
          : this.selectedTripType.value;

      this.$http
        .get(
          `/admin/panel/get_prices_selfpay_by_miles_triptype/${miles}/${tripType}`
        )
        .then((response) => {
          this.pricesSettings = response.data.data;
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async getListPatients() {
      await this.$http.get(`admin/panel/get_selfpays`).then((res) => {
        if (res.data.message) {
          this.listSelfpay = res.data.data;
          //remove white space phones
          this.listSelfpay.forEach((x) => {
            if (x.phone_number) {
              x.phone_number = x.phone_number.replace(/\s+/g, '');
            }
          })
        }
      });
      return true;
    },
    async getPatientByPhone(phoneNumber) {
      let resultData = null;

      await this.$http.get(`admin/panel/get_selfpay_by_phone/`+phoneNumber).then((res) => {
        if (res.data.message) {
          resultData = res.data.data;
        }
      });
      return resultData;
    },
    async getDataSearchInfo(booking_type) {
      this.loadMenus(booking_type);
      await this.getCorporateAccounts();
      let hasSearchNumber = await this.searchNummber();
      this.getPricesSettings();
      //let hasListPatient = await this.getListPatients();
      this.getStates("");
      this.selectedTripType = this.optionsTriptype[0];

      //if (hasListPatient && hasSearchNumber) {
        if (hasSearchNumber) {
        if (this.$route.query.phone) {
          this.phoneFieldEmpty = false;
          this.numberField();

          if (this.currentPhone) {
            let selectValue = await this.getPatientByPhone(this.currentPhone.phone);
            this.setCurrentPatient(selectValue);

            let lat = this.getLat(this.dataRequest.from_coordinates);
            let log = this.getLog(this.dataRequest.from_coordinates);
            this.setTimeZone(null);
            this.getCitiesByLocation(lat, log);
          }

          // this.listSelfpay.forEach((element) => {
          //   if (this.currentPhone) {
          //     if (element.phone_number == this.currentPhone.phone) {
          //       this.setCurrentPatient(element);

          //       let lat = this.getLat(this.dataRequest.from_coordinates);
          //       let log = this.getLog(this.dataRequest.from_coordinates);
          //       this.setTimeZone(null);
          //       this.getCitiesByLocation(lat, log);
          //     }
          //   }
          // });
        }
      }

    },
    getInfoBooking() {
      this.dataCa = this.$store.getters["Users/userData"].user;
    },
    //ACTIONS
    async formRequest() {
      let createInvoice = Number(this.create_invoice);
      let url = `admin/panel/booking/add`;
      let paramClientType = this.selectedTypeBooking.value == this.SELFPAY ? "" : "?clientType=reservationCode";

      //if createinvoice is equal 0 no create the payment
      if (createInvoice === 0) {
        url = `admin/panel/booking/add/0`
      }
      //send the form to server
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.dataRequest.selfpay_id = parseInt(this.idpaciente.value);
      this.dataRequest.booking_date = this.appointmentdate + " " + this.appointmenttime;
      this.dataRequest.appoinment_datetime = this.appointmentdate + " " + this.appointmenttime;
      this.dataRequest.trip_type = this.selectedTripType.value;
      this.dataRequest.surgery_type = this.selectedSurgery.value;
      this.dataRequest.notes = this.notes;
      this.dataRequest.totalAdditionalStop = this.dataRequest.listAdditionalStops.length;
      this.dataRequest.is_pickup_ontime = this.selectedIsPickupOntime.value;
      this.dataRequest.state = this.selectedState.value;
      this.dataRequest.city = this.selectedCity.value;
      this.dataRequest.time_zone = this.selectedTimeZone.value;
      this.dataRequest.garage_time = this.getCentralTime(this.dataRequest, "YYYY-MM-DD HH:mm:ss");
      this.dataRequest.is_reserve_by_ca = this.selectedTypeBooking.value == this.SELFPAY ? 0 : 1;
      this.dataRequest.service_type = this.selectedService == null ? null : this.selectedService.value;
      this.dataRequest.create_invoice = Number(this.create_invoice);
      this.dataRequest.payment_method = this.selectedPaymentMethodId;

      if (this.dataRequest.is_automatic_pickup_address == 0) {
        this.dataRequest.from_coordinates = this.fromLatitude + "," + this.fromLongitude;
      }

      if (this.dataRequest.is_automatic_destination_address == 0) {
        this.dataRequest.to_coordinates = this.toLatitude + "," + this.toLongituded;
      }

      await this.$http
        .post(url + `${paramClientType}`, this.dataRequest)
        .then((response) => {
          if (response.data.status === 200) {
            this.$swal({
              title: response.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.$router.push({ name: "assign-driver" });
          } else {
            this.$swal({
              title: response.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: error.response.data.data,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    addAdditionalStop() {
      if (this.additionalStop != null) {
        this.dataRequest.listAdditionalStops.push(this.additionalStop);
        this.searchAdditionalStop = null;
        this.additionalStop = null;
        this.doCalculations();
      }
    },
    deleteAdditionalStop(item) {
      let index = this.dataRequest.listAdditionalStops.indexOf(item);
      if (index > -1) {
        this.dataRequest.listAdditionalStops.splice(index, 1);
        this.doCalculations();
      }
    },
    //calculate distance and price
    async doCalculations() {
      if (this.dataRequest.is_automatic_miles == "0") {
        this.calculatePrice();
        return false;
      }
      this.listAddresses = [];
      //add begining
      addAddressToList(
        this.dataRequest.from,
        this.dataRequest.from_coordinates,
        0,
        0,
        this.listAddresses
      );

      //add additional points
      for (let i = 0; i < this.dataRequest.listAdditionalStops.length; i++) {
        addAddressToList(
          this.dataRequest.listAdditionalStops[i].to,
          this.dataRequest.listAdditionalStops[i].coordinate,
          0,
          0,
          this.listAddresses
        );
      }

      //add ending
      addAddressToList(
        this.dataRequest.to,
        this.dataRequest.to_coordinates,
        0,
        0,
        this.listAddresses
      );

      if (this.listAddresses.length > 1) {
        //calculate distance
        await this.calculateDistanceIncludingAdditionalPoints(
          this.listAddresses
        );

        //calculate price
        this.calculatePrice();
      }
    },
    //calculate the distance between the start and each point then sort each point by distance between start and the point
    async calculateDistancesFromStartToPoints() {
      let self = this;
      self.listAddressesOrdered = [];

      //set the origin point
      let from = {
        address: this.listAddresses[0].address,
        coordinates: this.listAddresses[0].coordinates,
        distanceFromStart: 0,
        distanceBetwentPoints: 0,
      };

      //set the additional point and the end destination point
      for (let i = 0; i < this.listAddresses.length; i++) {
        //get the info of the current point and save into "to" variable
        let to = {
          address: this.listAddresses[i].address,
          coordinates: this.listAddresses[i].coordinates,
          distanceFromStart: null,
          distanceBetwentPoints: null,
        };

        //if is the begining or the ending dont do calculations
        if (i === 0 || i === this.listAddresses.length - 1) {
          self.listAddressesOrdered.push(to);
          continue;
        }

        //proccess the point to call google api map
        let searchComa = to.coordinates.indexOf(",");
        let latud = to.coordinates.substring(0, searchComa);
        let longi = to.coordinates.substring(
          searchComa + 1,
          to.coordinates.length
        );

        //longitúd y latitúd from
        let searchComaFrom = from.coordinates.indexOf(",");
        let latudFrom = from.coordinates.substring(0, searchComaFrom);
        let longiFrom = from.coordinates.substring(
          searchComaFrom + 1,
          from.coordinates.length
        );

        let origin1 = new google.maps.LatLng(latud, longi);
        let origin2 = to.address;
        let destinationA = from.address;
        let destinationB = new google.maps.LatLng(latudFrom, longiFrom);

        //call the google maps api
        let service = new google.maps.DistanceMatrixService();
        await service.getDistanceMatrix(
          {
            origins: [origin1, origin2],
            destinations: [destinationA, destinationB],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          function (response, status) {
            if (response) {
              if (response.rows[0].elements[0].distance) {
                to.distanceFromStart = response.rows[0].elements[0].distance.value / 1609.34;

                //add item to listAddress
                self.listAddressesOrdered.push(to);

                //order the listAddresses according to distanceFromStart
                self.listAddressesOrdered.sort(function (a, b) {
                  return (
                    parseFloat(a.distanceFromStart) -
                    parseFloat(b.distanceFromStart)
                  );
                });
              }
            }
          }
        );
      }
    },
    //calculate the distance including the additional points
    async calculateDistanceIncludingAdditionalPoints() {
      let self = this;
      self.dataRequest.total_miles = 0;

      await self.calculateDistancesFromStartToPoints();

      //set the additional point and the end destination point
      for (let i = 1; i < self.listAddressesOrdered.length; i++) {
        let from = self.listAddressesOrdered[i - 1];
        let to = self.listAddressesOrdered[i];

        //proccess the point to call google api map
        let searchComa = to.coordinates.indexOf(",");
        let latud = to.coordinates.substring(0, searchComa);
        let longi = to.coordinates.substring(
          searchComa + 1,
          from.coordinates.length
        );

        //longitúd y latitúd from
        let searchComaFrom = from.coordinates.indexOf(",");
        let latudFrom = from.coordinates.substring(0, searchComaFrom);
        let longiFrom = from.coordinates.substring(
          searchComaFrom + 1,
          to.coordinates.length
        );

        let origin1 = new google.maps.LatLng(latud, longi);
        let origin2 = to.address;
        let destinationA = from.address;
        let destinationB = new google.maps.LatLng(latudFrom, longiFrom);

        //call the google maps api
        let service = new google.maps.DistanceMatrixService();
        await service.getDistanceMatrix(
          {
            origins: [origin1, origin2],
            destinations: [destinationA, destinationB],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          function (response, status) {
            if (response) {
              if (response.rows[0].elements[0].distance) {
                let distance = response.rows[0].elements[0].distance.value / 1609.34;

                if (self.selectedTripType) {
                  if (self.selectedTripType.value === self.ROUNTRIP) {
                    distance = distance * 2;
                  }
                }

                self.dataRequest.total_miles += distance;
              }
            }
          }
        );
      }
    },
    async calculatePrice() {
      if (this.dataRequest.is_automatic_price != 1) {
        return false;
      }
      let formData = new FormData();
      let miles = this.dataRequest.total_miles;
      let waitingTime = this.dataRequest.waitingTime;
      let tripType = this.selectedTripType.value === null ? this.optionsTriptype[0].value : this.selectedTripType.value;
      let additionalStop = this.dataRequest.listAdditionalStops.length;
      let corporate_account_id = this.selectedCorporateAccount.value;
      let servicetype = this.selectedService.value;

      //let url = `admin/panel/calculatePriceSelfPay/${miles}/${tripType}/${waitingTime}/${additionalStop}`;
      let url = `admin/panel/calculate_price_selfpay_nup`;

      //if is corporate account or contract account
      if (this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT || this.selectedTypeBooking.value == this.CONTRACT) {
        //url = `admin/panel/calculate_price_corporate_account/${servicetype}/${miles}/${waitingTime}/${tripType}/${corporate_account_id}/${additionalStop}`;
        url = `admin/panel/calculate_price_corporate_account_nup`;
      }

      formData.append("servicetype", servicetype);
      formData.append("miles", miles);
      formData.append("waitingTime", waitingTime);
      formData.append("tripType", tripType);
      formData.append("corporate_account_id", corporate_account_id);
      formData.append("additionalStop", additionalStop);
      formData.append("bookingId", this.dataRequest.id);

      this.$swal({
        title: "Please, wait calculating price...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      await this.$http
        .post(
          url, formData
        )
        .then((res) => {
          this.dataRequest.price = res.data.data.totalTrip;
          this.tripSupported = res.data.data.tripSupported;
          this.dataRequest.total_additional_stops = res.data.data.totalAdditionalStop;
          this.dataRequest.totalWaitingTime = res.data.data.totalWaitingTime;

          this.feeAdditionalStop = res.data.data.additional_stop_fee;
          this.feeWaitingTime = res.data.data.wait_time_fee;
          if (this.dataRequest.is_automatic_price === 1 || this.dataRequest.is_automatic_price === "1") {
            this.dataRequest.additional_stops_charges = res.data.data.totalAdditionalStop;
            this.dataRequest.wait_time_charges = res.data.data.totalWaitingTime;
            this.dataRequest.miles_charges = res.data.data.totalMiles;

            let isSelfpay = (this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT || this.selectedTypeBooking.value == this.CONTRACT) ? false : true;
            if (isSelfpay == false) {
              this.dataRequest.base_charge = res.data.data.totalBaseEachWay;
              this.dataRequest.additional_miles_charges = null;
              this.dataRequest.minimum_fee = res.data.data.minimum_fee;
            } else {
              this.dataRequest.base_charge = null;
              this.dataRequest.additional_miles_charges = res.data.data.totalAdditionalMiles;
            }

          }

          this.$swal.close();
        })
        .catch((res) => {
          this.$swal({
            title: getMessageError(res),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    //LOAD OR FORMAT DATA
    async searchNummber() {
      let self = this;
      await this.$http
        .post(`ca/1/panel/selfpay/searchNumber`, this.postData)
        .then((res) => {
          self.listPhones = res.data.data;
          this.disablePhone = false;
          //remove white space listphones
          self.listPhones.forEach((x) => {
            if (x.phone) {
              x.phone = x.phone.replace(/\s+/g, '');
            }
          })
        })
        .catch((error) => {
          this.$swal({
            title: error.response.data.data,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return true;
    },
    numberFieldOpen() {
      this.phoneFieldEmpty = true;
      if ((this.currentPhone = "")) {
        this.phoneFieldEmpty = false;
      }
    },
    numberField() {
    },
    isNumberVar: function (event) {
      let regex = new RegExp("^[-Z0-9 ]+$");
      let key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    isText: function (event) {
      let regex = new RegExp("^[a-zA-Z ]+$");
      let key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    showNextButtom(showButton) {
      const $nextBtn = document.querySelectorAll("#aride .wizard-footer-right");
      if (showButton == false) {
        $nextBtn[0].style.display = "none";
      } else {
        $nextBtn[0].style.display = "block";
      }
    },
    loadPatientInformation(element) {
      if (!element) {
        element = {
          id: null,
          name: null,
          lastname: null,
          phone_number: null,
          email: null,
          address: null,
          gps_address: null,
          client_id: null,
          card_use: null,
        }
      }

      this.phoneFieldEmpty = false;
      this.firstNamePatient = element.name;
      this.lastNamePatient = element.lastname;
      this.contactPatient = element.phone_number;
      this.emailPatient = element.email;
      this.address = element.address;
      this.gps_address = element.gps_address;
      this.client_id = element.client_id;
      this.is_verified_email = element.is_verified_email;
      this.is_verified_phone = element.is_verified_phone;
      // if (this.selectedTypeBooking.value == this.SELFPAY) {
      //   this.card_use = element.card_use;
      //   this.getCard(this.client_id);
      // }

      this.dataRequest.from = this.address;
      this.dataRequest.from_coordinates = this.gps_address;

      this.fromLatitude = this.getLat(this.dataRequest.from_coordinates);
      this.fromLongitude = this.getLog(this.dataRequest.from_coordinates);

      let lat = this.getLat(this.dataRequest.from_coordinates);
      let log = this.getLog(this.dataRequest.from_coordinates);
      this.getCitiesByLocation(lat, log);
      this.setTimeZone(null);

    },
    createSelfpaid() {
      this.$router.push({
        name: "create-selfpaid",
        query: { plan: "private", booking_type: this.selectedTypeBooking.value },
      });
    },
    createPatient() {
      if (this.selectedCorporateAccount == null) {
        this.$swal({
          title: "Please select a company",
        });
        return false;
      }

      this.$router.push({
        name: "create-patient",
        query: { plan: "private", ca_id: this.selectedCorporateAccount.value, booking_type: this.selectedTypeBooking.value },
      });
    },
    createSelfpay() {
      this.$router.push({
        name: "create-patient",
        query: { plan: "private", booking_type: this.selectedTypeBooking.value },
      });
    },
    timeReturn() {
      if (
        this.dataRequest.from !== "" &&
        this.dataRequest.to !== "" &&
        this.tiempoEstimado !== 0
      ) {
        let hourestimado = Math.floor(this.tiempoEstimado / 3600);
        hourestimado = hourestimado < 10 ? "0" + hourestimado : hourestimado;
        let minutetimado = Math.floor((this.tiempoEstimado / 60) % 60);
        minutetimado = minutetimado < 10 ? "0" + minutetimado : minutetimado;
        let secondestimado = this.tiempoEstimado % 60;
        secondestimado =
          secondestimado < 10 ? "0" + secondestimado : secondestimado;
        this.dataRequest.approximately_return_time =
          hourestimado + ":" + minutetimado + ":" + secondestimado;
      }
    },
    loadMenus(booking_type) {
      this.optionsTriptype = getMenuOptionsTripType();
      this.optionsSurgery = getMenuOptionsSurgeryOptions();
      this.optionsServiceOptions = getMenuOptionsServiceType();

      this.optionsIsPickupOntime = getMenuPickupOnTime();
      this.optionsTimezone = getMenuTimeZones();
      this.optionsTypeBooking = getMenuTypeBooking();

      this.selectedTripType = this.optionsTriptype[0];
      this.selectedSurgery = this.optionsSurgery[0];
      this.selectedService = this.optionsServiceOptions[0];
      this.selectedIsPickupOntime = this.optionsIsPickupOntime[0];
      this.selectedTypeBooking = this.optionsTypeBooking[0];
      if (booking_type != null) {
        this.selectedTypeBooking = this.optionsTypeBooking.find(x => x.value === booking_type);
      }

    },
    loadCorporateAccountsMenu() {
      this.idpaciente = null;
      this.optionsCoporateAccount = [];
      this.selectedCorporateAccount = { title: "", value: null };
      this.listCorporateAccounts.forEach(element => {
        let data = {};
        let typePayment = this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT ? 'cc' : 'il';
        let elementPayment = '';
        if (element.corporate_account_personal_info) {
          elementPayment = element.corporate_account_personal_info.payment_type;
        }

        if (elementPayment == typePayment) {
          data = {
            title: element.company_legal_name,
            value: element.id,
          }
          this.optionsCoporateAccount.push(data);
        }
      });

      // if (this.selectedTypeBooking.value == this.SELFPAY) {
      //   this.optionsServiceOptions = this.optionsServiceOptions.filter((item) => item.value !== 'bls');
      // } else {
      //   this.optionsServiceOptions = getMenuOptionsServiceType();
      // }
      this.optionsServiceOptions = getMenuOptionsServiceType();
    },
    //FORMAT VALUES
    toFixed(data, numFixed) {
      return toFixed(data, numFixed);
    },
    //STATES AND CITIES
    getStates(itemSeleted) {
      this.listStates = [];
      this.selectedState = { value: null };
      this.$http
        .get(`states_cities/get_states`)
        .then((response) => {
          this.loadMenuStates(response.data.data, itemSeleted);
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getCitiesByState(state, city) {
      this.listCities = [];
      this.selectedCity = null;
      this.$http
        .get(`states_cities/get_cities_bystate/${state}`)
        .then((response) => {
          this.dataCities = response.data.data;
          this.loadMenuCities(response.data.data, city);
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getCitiesByLocation(lat, log) {
      if (lat == null || log == null) {
        return false;
      }

      this.listCities = [];
      this.selectedCity = null;
      this.$http
        .get(`states_cities/get_state_city_bylocation/${lat}/${log}`)
        .then((response) => {
          let data = response.data.data.results[0];
          this.setStateCity(data);
        })
        .catch((error) => {
          console.log(getMessageError(error));
        });
    },
    loadMenuStates(listData, itemSeleted) {
      this.listStates = [];
      listData.forEach((element) => {
        let data = {
          value: `${element.state_full}`,
          title: `${element.state_full}`,
        };
        this.listStates.push(data);
      });
      this.selectedState = this.listStates.find((x) => x.value == itemSeleted);
    },
    loadMenuCities(listData, itemSeleted) {
      this.listCities = [];
      listData.forEach((element) => {
        let data = {
          value: `${element.city}`,
          title: `${element.city}`,
        };
        this.listCities.push(data);
      });
      this.selectedCity = this.listCities.find((x) => x.value == itemSeleted);
    },
    getCityFromApiRespGoogle(data) {
      let item = "0";
      try {
        item = data.address_components.find((x) => x.types[0] == "locality");
        item = item.long_name;
        item = item.toUpperCase();
      } catch (error) { }
      return item;
    },
    getStateFromApiRespGoogle(data) {
      let item = "0";
      try {
        item = data.address_components.find(
          (x) => x.types[0] == "administrative_area_level_1"
        );
        item = item.long_name;
        item = item.toUpperCase();
      } catch (error) { }
      return item;
    },
    getCountyFromApiRespGoogle(data) {
      let item = "0";
      try {
        item = data.address_components.find(
          (x) => x.types[0] == "administrative_area_level_2"
        );
        item = item.long_name;
        item = item.toUpperCase();
        item = item.replace("COUNTY", "");
        item = item.replace("CONDADO DE", "");
        item = item.trim();
      } catch (error) { }
      return item;
    },
    getCentralTime(booking) {
      let res = "";
      if (booking.time_zone) {
        res = getCentralTime(booking, "YYYY-MM-DD HH:mm");
      }
      return res;
    },
    setTimeZone(pTimeZone) {
      let timeZone = null;

      if (pTimeZone != null) {
        timeZone = pTimeZone;
      } else {
        if (this.selectedCity) {
          let city = this.dataCities.find((x) => x.city == this.selectedCity.value);
          if (city) {
            timeZone = city.time_zone;
          }
        }
      }

      this.selectedTimeZone = this.optionsTimezone.find((x) => x.value == timeZone);

    },
    async addCityAutomatically(state, city, time_zone) {
      const formData = new FormData();

      formData.append("state_full", state);
      formData.append("city", city);
      formData.append("time_zone", time_zone);

      await this.$http
        .post(`states_cities/add_city_state`, formData)
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async setStateCity(loc) {
      //get state and city
      let lat = 0;
      let lon = 0;

      if (loc.geometry.location.lat instanceof Function) {
        lat = loc.geometry.location.lat();
        lon = loc.geometry.location.lng();
      } else {
        lat = loc.geometry.location.lat;
        lon = loc.geometry.location.lng;
      }


      let timeZone = await this.getTimeZone(lat, lon);

      let state = this.getStateFromApiRespGoogle(loc);
      let city = this.getCityFromApiRespGoogle(loc);

      if (state && city) {
        await this.addCityAutomatically(state, city, timeZone)
      }

      let place = `${city}`;
      //load menu
      this.getStates(state);
      this.getCitiesByState(state, place);
      this.setTimeZone(timeZone);
    },
    async getTimeZone(lat, lon) {
      let res = null;
      await this.$http
        .get(`getTimeZone/${lat}/${lon}`)
        .then((response) => {
          if (response.data.timeZoneId) {
            res = response.data.timeZoneId;
          }
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    getLat(data) {
      let res = null;

      try {
        res = data.split(",")[0];
      } catch (error) { }

      return res;
    },
    getLog(data) {
      let res = null;

      try {
        res = data.split(",")[1];
      } catch (error) { }

      return res;
    },
    ///PAYMENT METHODS
    getCard(id) {
      if (id == null || id == "") {
        return false;
      }

      let url = `admin/panel/selfpaid/${id}/paymentMethod`;
      this.resetPaymentInfo();

      if (this.selectedTypeBooking.value == this.CONTRACT || this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT) {
        url = `admin/panel/ca/${id}/payment_method`;
      }
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.data) {
            this.paymentMethods = response.data.data.payment === undefined ? { brand: "" } : response.data.data.payment;
            this.client = response.data.data.client;
          } else {
            this.paymentMethods = { brand: "" };
          }
          this.setVariablesPaymentInfo(this.paymentMethods);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setShowInfoPayment() {
      this.showData = true;
      this.saveData = false;
      this.newData = false;
      this.showNextButtom(true);
    },
    setEditInfoPayment() {
      this.showData = false;
      this.saveData = true;
      this.newData = false;
      //this.showNextButtom(false);
    },
    setSaveInfoPayment() {
      this.showData = false;
      this.saveData = true;
      this.newData = true;
    },
    setNewInfoPayment() {
      this.showData = false;
      this.saveData = false;
      this.newData = true;
    },
    resetPaymentInfo() {
      this.paymentMethods.name = null;
      this.paymentMethods.last4 = "";
      this.seletedCardUsed = null;
      this.paymentMethods.exp_year = null;
    },
    saveChanges() {
      this.formSubmitted();
    },
    setVariablesPaymentInfo(data) {
      this.cargando = false;
      if (data.brand !== "") {
        this.setShowInfoPayment();
      } else {
        this.setNewInfoPayment();
      }
    },
    setCurrentPatient(element) {
      this.firstNamePatient = element.name;
      this.lastNamePatient = element.lastname;
      this.contactPatient = element.phone_number;
      this.emailPatient = element.email;
      this.address = element.address;
      this.gps_address = element.gps_address;
      this.idpaciente = element.id;
      this.client_id = element.client_id;
      this.card_use = element.card_use;

      this.dataRequest.from = this.address;
      this.dataRquest.from_coordinates = this.gps_address;
    },

    async loadPreviuosValues(ca_id, patientId, booking_type, phone) {
      this.selectedTypeBooking = this.optionsTypeBooking.find(x => x.value === booking_type);
      if (booking_type == this.CORPORATE_ACCOUNT || booking_type == this.CONTRACT) {
        let corporateAccountOptionSeleted = this.optionsCoporateAccount.find(x => x.value === ca_id);
        this.selectedCorporateAccount = corporateAccountOptionSeleted;
        await this.getPatientsCorporateAccounts(ca_id);
        let data = this.optionsPatients.find(x => x.value === patientId);
        this.idpaciente = data;

        document.getElementsByClassName('wizard-btn')[0].click()
        setTimeout(() => { document.getElementsByClassName('wizard-btn')[1].click(); }, 500);
        setTimeout(() => { document.getElementsByClassName('wizard-btn')[1].click(); }, 500);
      }
      if (booking_type == this.SELFPAY) {
        phone = phone.replace(/\s+/g, '');
        let currentPhone = this.listPhones.find(x => x.phone === phone);
        this.currentPhone = currentPhone;
        document.getElementsByClassName('wizard-btn')[0].click();
        setTimeout(() => { document.getElementsByClassName('wizard-btn')[1].click(); }, 500);
        setTimeout(() => { document.getElementsByClassName('wizard-btn')[1].click(); }, 500);
      }
    },
    async loadInformation() {
      let booking_type = null;
      let ca_id = 0;
      let patientId = 0;
      let loadPreviusInformation = false;
      let phone = null;
      if (this.$route.query.load_previous_information === 1 || this.$route.query.load_previous_information === "1") {
        loadPreviusInformation = true;
      }
      if (this.$route.query.booking_type) {
        booking_type = this.$route.query.booking_type;
      }
      if (this.$route.query.ca_id) {
        ca_id = this.$route.query.ca_id;
      }
      if (this.$route.query.id_patient) {
        patientId = this.$route.query.id_patient;
      }
      if (this.$route.query.phone) {
        phone = this.$route.query.phone;
      }

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      await this.getDataSearchInfo(booking_type);

      this.showNextButtom(true);

      this.$swal.close();

      if (loadPreviusInformation === true) {
        this.loadPreviuosValues(ca_id, patientId, booking_type, phone);
      }
    },
    openCreateRate() {
      let servicetype = "";
      let ca_id = null;
      if (this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT || this.selectedTypeBooking.value == this.CONTRACT) {
        if (this.selectedService) {
          servicetype = this.selectedService.value;
        }
        if (this.selectedCorporateAccount) {
          ca_id = this.selectedCorporateAccount.value;
        }
        const routeData = this.$router.resolve({ path: `/form-prices-corporate-account/0/${servicetype}/${ca_id}`, query: { redirect: 1 } });
        window.open(routeData.href, '_blank');
      }
    },
    async changeServiceType() {
      await this.calculatePrice();

      if (this.tripSupported === "no") {
        this.$refs.modalcreaterates.show();
        return false;
      }
    },
    getIsVerified(data) {
      let res = "It's verified";
      if (!data || data == "no") {
        res = "It's not verified"
      }
      return res;
    },
    submitPhoneCode(phone) {
      if (phone === null || phone.trim() == "") {
        return false;
      }

      let formData = new FormData();

      formData.append("phone_number", phone);
      formData.append("id", this.idpaciente.value);

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .post("admin/panel/verify/sendverificationphonecode", formData)
        .then((res) => {
          this.$swal.close();
          if (res.data.status === 200) {
            this.$bvModal.show("modal-phone");
          }
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    submitEmailCode(email) {
      if (email == "") {
        email = false;
      } else {
        this.$bvModal.show("modal-validate-email");
        let formData = new FormData();
        formData.append("email", email);
        this.$http
          .post("ca/panel/client/saveEmailCode", formData)
          .then((res) => {
            if (res.data.status === 200) {
            }
          })
          .catch((res) => { });
      }
      c
    },
    async verifyEmail(email) {
      if (email == "") {
        return false;
      } else {
        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        let formData = new FormData();
        formData.append("code", email);
        let res = await this.$http
          .post("ca/panel/client/verifyEmailCode", formData)
          .then((res) => {
            if (res.data.status === 200) {
              this.is_verified_email = "yes";
              this.$bvModal.hide("modal-validate-email");
              this.UpdateVerificationInformation(this.idpaciente.value, this.is_verified_email, this.is_verified_phone);
            }
            return res.data.message;

          })
          .catch((res) => {
            this.is_verified_email = "no";
            return getMessageError(res);
          });

        this.$swal({
          title: res,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });

      }
    },
    async verifyPhoneCode(codePhone, phone_number) {
      let formData = new FormData();
      formData.append("code", codePhone);
      formData.append("phone_number", phone_number);

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let res = await this.$http
        .post("admin/panel/validateverificationphonecode/no", formData)
        .then((res) => {
          if (res.data.status === 200) {
            this.is_verified_phone = "yes";
            this.$bvModal.hide("modal-validate-email");
            this.codePhone = "";
            this.UpdateVerificationInformation(this.idpaciente.value, this.is_verified_email, this.is_verified_phone);
          }
          return res.data.message;
        })
        .catch((res) => {
          this.is_verified_phone = "no";
          return getMessageError(res);
        });

      this.$swal({
        title: res,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    async UpdateVerificationInformation(idSelfpay, is_verified_phone, is_verified_email) {
      let formData = new FormData();
      formData.append("id", idSelfpay);
      formData.append("is_verified_phone", is_verified_phone);
      formData.append("is_verified_email", is_verified_email);

      await this.$http
        .post("admin/panel/update-verification-information", formData)
        .then((res) => {
          if (res.data.status === 200) {
            this.is_verified_phone = "yes";
            this.$bvModal.hide("modal-validate-email");
            this.codePhone = "";
          }
          return res.data.message;
        })
        .catch((res) => {
          this.is_verified_phone = "no";
          return getMessageError(res);
        });

    },
    setValuesWalkIn() {
      if (this.appointmenttime == null || this.appointmenttime == "" || this.appointmenttime == undefined) {
        this.appointmenttime = "00:00";
      }
    },
    async getListPaymentMethods(isSelfpay, id, updateEditAddPaymentComponent = false) {
      this.selectedPaymentMethodId = null;
      this.isTherePaymentMethods = true;
      this.statusPageListPaymentMethods = "Loading information...";
      this.selectIdCustomerPaymentMehtod = id;
      this.listPaymentMethods = [];
      this.keyBookingPaymentsMethod = this.keyBookingPaymentsMethod + 1;
      this.keyPageEditPaymentSelfpay = this.keyPageEditPaymentSelfpay + 1;
      if (updateEditAddPaymentComponent == true) {
        this.keyPageEditPaymentCa = this.keyPageEditPaymentCa + 1;
      }

      let url = "";
      if (isSelfpay == true) {
        url = `/admin/panel/get_selfpay_payment_methods/${id}`;
      } else {
        url = `/admin/panel/get_ca_payment_methods/${id}`;
      }
      await this.$http
        .get(url)
        .then((response) => {
          this.listPaymentMethods = response.data.data;

          let defaultPaymentMethod = this.listPaymentMethods.find(x => x.is_default == 0);
          if (defaultPaymentMethod) {
            this.selectedPaymentMethodId = defaultPaymentMethod.stripe_payment_method_id;
          }

          if (this.listPaymentMethods.length == 0) {
            this.isTherePaymentMethods = false;
          }
          this.keyBookingPaymentsMethod = this.keyBookingPaymentsMethod + 1;
          this.keyPageEditPaymentSelfpay = this.keyPageEditPaymentSelfpay + 1;
          if (updateEditAddPaymentComponent == true) {
            this.keyPageEditPaymentCa = this.keyPageEditPaymentCa + 1;
          }
        })
        .catch((res) => {
          this.statusPageListPaymentMethods = "";
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      this.statusPageListPaymentMethods = "";
    },
    updatePaymentMethodList(updateEditAddPaymentComponent = false) {
      let isSelfpay = this.selectedTypeBooking.value == this.CORPORATE_ACCOUNT ? false : true;

      if (isSelfpay == true) {
        this.getListPaymentMethods(true, this.idpaciente.value);
      } else {
        this.getListPaymentMethods(false, this.selectedCorporateAccount.value, updateEditAddPaymentComponent);
      }
    },
    setSeletedPaymentMethod(paymentMethodId) {
      this.selectedPaymentMethodId = paymentMethodId;
    },
    async SetPhoneNumberWatch() {
      let selectValue = await this.getPatientByPhone(this.currentPhone.phone);
      this.idpaciente = { value: selectValue.id };
      this.loadPatientInformation(selectValue);
    },
    async SetpacienteWatch(seletedIdPatient) {
      let data=await this.GetPatientById(seletedIdPatient);
      this.loadPatientInformation(data);
    },
    async GetPatientById(id){
       let resultData = null;

      await this.$http.get(`admin/panel/get_selfpay_by_id/`+id).then((res) => {
        if (res.data.message) {
          resultData = res.data.data;
        }
      });
      return resultData;
    }

  },
  beforeMount() {
    this.getInfoBooking();
  },
  mounted() {
    this.loadInformation();
  },
};
</script>

<style lang="scss">
#aride .wizard-footer-right,
.col-md-4.text-right.m-auto,
#paragraphNumber {
  display: none;
}

.invoice-heading {
  background: #eee;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  margin-top: 10px;
}

.invoice-heading h3 {
  margin: auto;
  padding: 7px;
  font-weight: 600;
  text-align: left;
}

.row-data {
  padding: 15px 25px 5px 25px;
}

table.tbTotal,
table.tbTotal th,
table.tbTotal td {
  border: 1px solid #c6c6c6;
  margin-left: auto;
  margin-bottom: 20px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>